@import 'erpcore/assets/scss/main';

:root {
    --errorColor: #e93b3b;
    --primaryColorOriginal: #ffb78e;
    --primaryHoverOriginal: #ffa564;
    --primaryColor: #ffb78e;
    --primaryHover: #ffa564;
}

.background {
    @each $colorName, $colorValue in $colors {
        &--#{$colorName} {
            @if (type-of($colorValue) == string and str-index($colorValue, 'linear-gradient(')) {
                background-image: $colorValue;
            } @else {
                background-color: $colorValue;
            }
        }
    }
}

.color {
    @each $colorName, $colorValue in $colors {
        &--#{$colorName} {
            color: $colorValue !important;
            @if (type-of($colorValue) == string and str-index($colorValue, 'linear-gradient(')) {
                background: $colorValue;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}

.color-brand {
    color: getColor(light-blue);
}
