@import 'erpcore/assets/scss/main';

.mercure-reconnection-modal {
    .modal__header {
        padding: $spacing * 4;
        background: getColor(light-grey);

        @include breakpoint(mobile) {
            padding: $spacing * 3;
        }
    }

    .modal__content {
        @include breakpoint(mobile) {
            padding: $spacing * 3;
        }
    }

    .modal__title {
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    &__icon {
        flex: 0 0 30px;
        display: block;
        margin-right: $spacing * 2;

        svg {
            display: block;
            width: 30px;
            height: 30px;
            fill: getColor(orange);
        }
    }

    .modal__close {
        display: none !important;
    }

    .element-loader {
        .dot1 {
            fill: rgba(#000, 0.4);
        }

        .dot2 {
            fill: rgba(#000, 0.6);
        }

        .dot3 {
            fill: rgba(#000, 0.25);
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__col {
        flex: 0 0 100%;

        &:first-child {
            font-size: 16px;
            font-weight: 500;
        }

        &:not(:first-child) {
            // text-align: right;
        }
    }

    &__divider {
        position: relative;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $spacing * 3 auto;
        font-weight: 700;

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: -32px;
            right: -32px;
            width: calc(100% + 64px);
            height: 1px;
            background: getColor(grey-2);
            z-index: 1;

            @include breakpoint(mobile) {
                left: -24px;
                right: -24px;
                width: calc(100% + 48px);
            }
        }

        &-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 36px;
            width: 36px;
            height: 36px;
            text-align: center;
            margin: auto;
            border-radius: 50%;
            background: getColor(grey-2);
            z-index: 2;
        }
    }
}
