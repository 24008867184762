@import 'erpcore/assets/scss/main';

%element-loader-colors {
    .dot1 {
        fill: rgba(getColor(white), 0.3);
    }

    .dot2 {
        fill: rgba(getColor(white), 0.5);
    }

    .dot3 {
        fill: rgba(getColor(white), 1);
    }
}

%element-loader-darkk-colors {
    .dot1 {
        fill: getColor(blue);
    }

    .dot2 {
        fill: getColor(orange);
    }

    .dot3 {
        fill: getColor(light-blue);
    }
}

%button-reset-browser-styles {
    border: none;
    font: inherit;
    text-transform: inherit;
    text-align: inherit;
    text-decoration: none;
    color: inherit;
    background-image: none;
    background-color: transparent;
    vertical-align: inherit;
    user-select: inherit;
    touch-action: inherit;
    cursor: inherit;
    white-space: inherit;
}

.button {
    $button: &;
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    align-items: center;
    max-width: 100%;
    height: 40px;
    margin: 0 ($spacing * 2) 0 0;
    padding: 12px ($spacing * 2);
    border: none;
    border-radius: 3px;
    font: $font-button;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: getColor(white);
    fill: getColor(white);
    background-image: none;
    background-color: getColor(primary);
    vertical-align: middle;
    user-select: none;
    touch-action: manipulation;
    cursor: pointer;
    white-space: normal;
    transition: getTransition();

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: getColor(white);
        background: getColor(primary-hover);
    }

    &:disabled,
    &--disabled {
        background-color: getColor(silver-light-grey);
        cursor: not-allowed;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: getColor(white);
            background-color: getColor(silver-light-grey);
            opacity: 1;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    &--has-icon {
        display: flex;
        align-items: center;
    }

    &--icon-right {
        flex-direction: row-reverse;
    }

    &__label {
        display: inline-block;
        margin-right: $spacing;

        &:last-child {
            margin-right: 0;
        }
    }

    &__icon {
        position: relative;
        // top: -1px;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: $spacing;
        vertical-align: middle;
        fill: getColor(white);

        &--right {
            margin-left: $spacing;
            margin-right: 0 !important;
        }

        &:last-child {
            margin-right: 0;
        }

        #{$button} .element-loader .svg,
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: getColor(white);

            @extend %element-loader-colors;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .element-loader {
        margin: 0 auto;
        @extend %element-loader-colors;
    }

    &--secondary {
        color: getColor(dark-grey);
        background-color: transparent;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid getColor(grey-5);
            border-radius: 3px;
            z-index: 1;
            transition: getTransition();
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: getColor(dark-grey-hover);
            background-color: transparent;

            &:before {
                border: 1px solid getColor(dark-grey-hover);
            }

            #{$button}__icon {
                fill: getColor(dark-grey-hover);

                svg {
                    fill: getColor(dark-grey-hover);
                }
            }
        }

        #{$button}__icon {
            fill: getColor(grey-5);

            svg {
                fill: getColor(dark-grey);

                @extend %element-loader-darkk-colors;
            }
        }

        .element-loader {
            @extend %element-loader-darkk-colors;
        }

        &:disabled,
        &#{$button}--disabled {
            color: getColor(white);
            fill: getColor(white);
            background-color: getColor(silver-light-grey);
            cursor: not-allowed;

            &:before {
                border: 2px solid getColor(silver-light-grey);
            }

            &:hover,
            &:active,
            &:focus {
                color: getColor(white);
                fill: getColor(white);
                background-color: getColor(silver-light-grey);

                &:before {
                    border: 2px solid getColor(silver-light-grey);
                }
            }

            #{$button}__icon {
                fill: getColor(white);

                svg {
                    fill: getColor(white);
                }
            }
        }
    }

    &--tertiary {
        color: getColor(primary);
        background-color: transparent;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: getColor(primary-hover);
            background-color: transparent;
            opacity: 1;

            #{$button}__icon {
                fill: getColor(primary-hover);

                svg {
                    fill: getColor(primary-hover);
                }
            }
        }

        #{$button}__icon {
            fill: getColor(orange);

            svg {
                fill: getColor(orange);
                @extend %element-loader-darkk-colors;
            }
        }

        .element-loader {
            @extend %element-loader-darkk-colors;
        }

        &:disabled,
        &#{$button}--disabled {
            color: getColor(silver-light-grey);
            fill: getColor(silver-light-grey);
            background-color: transparent;
            cursor: not-allowed;

            &:hover,
            &:active,
            &:focus {
                color: getColor(silver-light-grey);
                fill: getColor(silver-light-grey);
                background-color: transparent;
            }

            #{$button}__icon {
                fill: getColor(silver-light-grey);

                svg {
                    fill: getColor(silver-light-grey);
                }
            }
        }
    }

    &--small {
        height: 30px;
        line-height: 15px;
        padding: 7px 12px;
        margin: 0 $spacing 0 0;

        &#{$button}--has-icon {
            padding-top: 6px;
            padding-bottom: 6px;
        }

        &:before {
            border-width: 1px;
        }

        #{$button}__icon {
            width: 18px;
            height: 18px;
            vertical-align: middle;
        }

        #{$button}__icon,
        #{$button}__label {
            margin-right: $spacing/2;

            &:last-child {
                margin-right: 0px;
            }
        }

        &:hover {
            &:before {
                border-width: 1px;
            }
        }
    }

    &--full {
        width: 100%;
    }

    &--loading {
        pointer-events: none;

        .element-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            backface-visibility: hidden;
            transform-style: preserve-3d;
            margin: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            @extend %element-loader-colors;
        }

        #{$button}__icon,
        #{$button}__label {
            opacity: 0;
        }
    }

    &--link {
        @extend .button--tertiary;

        padding: 0;
        height: $spacing * 2;
        color: getColor(primary);
        font-weight: 400;

        &:hover,
        &:active,
        &:focus {
            color: getColor(primary-hover);
            text-decoration: underline;
            opacity: 1;

            #{$button}__icon {
                fill: getColor(primary-hover);

                svg {
                    fill: getColor(primary-hover);
                }
            }
        }

        #{$button}__icon {
            fill: getColor(light-blue);

            svg {
                fill: getColor(light-blue);

                @extend %element-loader-darkk-colors;
            }
        }

        @include breakpoint($to: tablet) {
            margin-top: -2px;
        }
    }

    &--inverted {
        color: getColor(white);
        background-color: transparent;
        border: 1px solid getColor(grey-4);

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            background-color: transparent;
            border: 1px solid #fff;
            opacity: 1;
        }

        #{$button}__icon {
            fill: getColor(white);

            svg {
                fill: getColor(white);
            }
        }
    }

    &--dropdown {
        min-width: 126px;
        padding: 0 #{$spacing * 4+4px} 0 0;
        pointer-events: none;

        #{$button}__inner-button {
            padding: 12px 4px 12px ($spacing * 2);
        }

        .react-select__menu {
            top: 100%;
        }

        > * {
            pointer-events: auto;
        }
    }

    &--dropdown#{$button}--small {
        #{$button}__inner-button {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__inner-button {
        @extend %button-reset-browser-styles;
        position: relative;
        display: inline-block;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    &__toggle {
        @extend %button-reset-browser-styles;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: #{$spacing * 4+4px};
        display: flex;
        justify-content: center;
        align-items: center;
        transition: getTransition();
        outline: none;

        #{$button}:not(#{$button}--disabled):hover & {
            background: rgba(getColor(main-grey), 0.1);
        }

        &-icon {
            display: block;
            width: 10px;
            height: 10px;
            margin-left: -2px;
            transition: getTransition();

            #{$button}--dropdown-open & {
                transform: rotate(180deg);
            }

            svg {
                display: block;
                width: 10px;
                height: 10px;
                fill: getColor(white);

                #{$button}--secondary:not(#{$button}--disabled) &,
                #{$button}--tertiary:not(#{$button}--disabled) & {
                    fill: getColor(main-grey);
                }
            }
        }
    }

    .react-select {
        &__control {
            display: none !important;
        }

        &__option--is-focused {
            color: getColor(silver-grey);
            background-color: transparent;
        }

        &__option--is-selected {
            color: white;
            background-color: getColor(silver-light-grey);
        }
    }

    &--action {
        display: inline-block;
        margin: 0 $spacing;
        margin-top: -4px;
        padding: 0px;
        height: 17px;
        background: transparent !important;
        border: none;

        &.button--small.button--has-icon {
            padding: 0;
        }

        svg path {
            fill: getColor(grey-3) !important;
        }

        &:hover {
            svg path {
                fill: getColor(primary) !important;
            }
        }

        #{$button}__icon {
            display: block;
            top: 0;
            width: 14px;
            height: 14px;
            margin: 0 auto;
        }
    }

    &--table-action {
        display: flex;
        cursor: pointer;
        border-radius: 0;
        flex-wrap: nowrap;
        text-align: center;
        align-items: center;
        height: $spacing * 3;
        align-content: center;
        text-decoration: none;
        justify-content: center;
        border: none;
        background-color: transparent;
        padding: 0;
        -webkit-appearance: none;

        svg {
            transition: getTransition();
            fill: getColor(black);
        }

        &:hover {
            svg {
                fill: getColor(orange);
            }
        }

        .button__icon {
            top: 0;
            width: $spacing * 2;
            height: auto;
        }

        .element-loader {
            @extend %element-loader-darkk-colors;
        }
    }

    @each $colorName, $colorCode in $colors {
        &--#{$colorName} {
            background-color: $colorCode;

            &:hover,
            &:active,
            &:focus {
                background-color: $colorCode;
            }
        }
    }
}
